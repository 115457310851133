import React from "react"
import { graphql } from "gatsby"
import { ParsePostContentHTML } from "../../helpers/content.helpers"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Components
import BlockHours from '../../components/elements/02-blocks/BlockHours'
import HeroInnerpage from "../../components/elements/10-hero/HeroInnerpage";
import SectionAboutLightbox from "../../components/elements/03-sections/SectionAboutLightbox"
import SectionMenu from '../../components/elements/03-sections/SectionMenu'
import BlockHighlight from "../../components/elements/02-blocks/BlockHighlight";

const Page = ({ data }) => {

   let parsedData = data.wordpressPage.acf

   // PARSING HERO
   let heroData = parsedData.restaurant_hero_innerpage

   let oHero = {}
   if(heroData) {
      oHero = {
         left: {
            title: heroData.restaurant_hero_innerpage_left.restaurant_hero_innerpage_left_title,
            image_one: heroData.restaurant_hero_innerpage_left.restaurant_hero_innerpage_left_image_one,
            image_two: heroData.restaurant_hero_innerpage_left.restaurant_hero_innerpage_left_image_two
         },
         right: {
            image: heroData.restaurant_hero_innerpage_right.restaurant_hero_innerpage_right_image
         }
      }
   }

   // PARSING SECTION ABOUT
   let sectionAboutData = parsedData.restaurant_two_columns_about_lightbox_alt
   let oSectionAbout = {
      title: sectionAboutData.restaurant_two_columns_about_lightbox_alt_title,
      column_one: {
         text: sectionAboutData.restaurant_two_columns_about_lightbox_alt_column_one.restaurant_two_columns_about_lightbox_alt_column_one_text
      },
      column_two: {
         gallery: sectionAboutData.restaurant_two_columns_about_lightbox_alt_column_two.restaurant_two_columns_about_lightbox_alt_column_two_gallery
      }
   }

   // PARSING MENU
   let menu = data.wordpressPage.acf.restaurant_ep_menu

   let oSectionMenu = {
      title: menu.restaurant_ep_menu_title,
      menus: [],
      utils: []
   }


   if(menu.restaurant_ep_menu_sections) {
      menu.restaurant_ep_menu_sections.forEach(menuSection => {
         let tempMenu = {
            title: menuSection.restaurant_ep_menu_sections_title,
            items: []
         }

         menuSection.restaurant_ep_menu_sections_items.forEach(item => {
            let tempItem = {
               title: item.restaurant_ep_menu_sections_items_title,
               indicator: item.restaurant_ep_menu_sections_items_indicator,
               price: item.restaurant_ep_menu_sections_items_price
            }

            tempMenu.items.push(tempItem)
         })

         oSectionMenu.menus.push(tempMenu)
      })
   }

   if(menu.restaurant_ep_menu_utils) {
      menu.restaurant_ep_menu_utils.forEach(util => {
         oSectionMenu.utils.push(util.restaurant_ep_menu_utils_text)
      })
   }

   // Parsing CTA
   let ctaData = data.wordpressPage.acf.restaurant_cta
   let oBlockHighlight = {
      title: ctaData.restaurant_cta_title,
      text: ctaData.restaurant_cta_text
   }

   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />

         <div className="site page page--restaurant">
            <BlockHours />
            
            <main id="main">
               { oHero && <HeroInnerpage oHero={oHero} /> }

               <h2 className="background-line container"><span>{parsedData.restaurant_title}</span></h2>

               <div className="container container-l container--spaced-bottom">
                  {ParsePostContentHTML(data.wordpressPage.content)}
               </div>

               <div className="container">
                  <SectionMenu oSectionMenu={oSectionMenu} />

                  <div className="container">
                     <BlockHighlight oBlockHighlight={oBlockHighlight} />
                  </div>

                  { (oSectionAbout.column_one.text || oSectionAbout.column_two.gallery) && <SectionAboutLightbox oSectionAbout={oSectionAbout} /> }
               </div>
            </main>
         </div>
      </Layout>
   )
}

export default Page

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         wordpress_id
         content
         title
         acf {
            restaurant_cta {
               restaurant_cta_text
               restaurant_cta_title
            }
            restaurant_title
            restaurant_hero_innerpage {
               restaurant_hero_innerpage_left {
                  restaurant_hero_innerpage_left_image_one
                  restaurant_hero_innerpage_left_image_two
                  restaurant_hero_innerpage_left_title
               }
               restaurant_hero_innerpage_right {
                  restaurant_hero_innerpage_right_image
               }
            }
            restaurant_two_columns_about_lightbox_alt {
               restaurant_two_columns_about_lightbox_alt_title
               restaurant_two_columns_about_lightbox_alt_column_two {
                  restaurant_two_columns_about_lightbox_alt_column_two_gallery
               }
               restaurant_two_columns_about_lightbox_alt_column_one {
                  restaurant_two_columns_about_lightbox_alt_column_one_text
               }
            }
            restaurant_ep_menu {
               restaurant_ep_menu_sections {
                 restaurant_ep_menu_sections_items {
                   restaurant_ep_menu_sections_items_indicator
                   restaurant_ep_menu_sections_items_price
                   restaurant_ep_menu_sections_items_title
                 }
                 restaurant_ep_menu_sections_title
               }
               restaurant_ep_menu_title
               restaurant_ep_menu_utils {
                 restaurant_ep_menu_utils_text
               }
            }
         }
      }
   }
`
